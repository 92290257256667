import * as t from '../types'

const initialState = {
  unsubscribe: '',
};

export default function(state= initialState, action) {
  const {type, payload} = action;

  switch(type) {

    case t.UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        unsubscribe: 'true',
      }

    case t.UNSUBSCRIBE_FAIL:
      return {
        ...state,
        unsubscribe: 'false',
      }

    default:
      return state
  }
}