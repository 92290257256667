export const ADD_TO_CART = "ADD_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";

export const GET_ALL_GOODS_SUCCESS = 'GET_ALL_GOODS_SUCCESS';
export const GET_ALL_GOODS_FAIL = 'GET_ALL_GOODS_FAIL';
export const GET_ONE_GOODS_SUCCESS = 'GET_ONE_GOODS_SUCCESS';
export const GET_ONE_GOODS_FAIL = 'GET_ONE_GOODS_FAIL';
export const GET_ALL_CATEGORIES_SUCCESS = 'GET_ALL_CATEGORIES_SUCCESS';
export const GET_ALL_CATEGORIES_FAIL = 'GET_ALL_CATEGORIES_FAIL';
export const SET_ORDER_DATA = 'SET_ORDER_DATA';
export const SET_ORDER_SUCCESS = 'SET_ORDER_SUCCESS';
export const SET_ORDER_FAIL = 'SET_ORDER_FAIL';
export const RESET_STATUS = 'RESET_STATUS';
export const CLOSE_BANNER = 'CLOSE_BANNER';
export const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS';
export const GET_BANNER_FAIL = 'GET_BANNER_FAIL';
export const GET_COMMERCIAL_SUCCESS = 'GET_COMMERCIAL_SUCCESS';
export const GET_COMMERCIAL_FAIL = 'GET_COMMERCIAL_FAIL';
export const GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS';
export const GET_FAQ_FAIL = 'GET_FAQ_FAIL';
export const GET_EXTRA_SUCCESS = 'GET_EXTRA_SUCCESS';
export const GET_EXTRA_FAIL = 'GET_EXTRA_FAIL';
export const GET_ALL_SIZES_SUCCESS = 'GET_ALL_SIZES_SUCCESS';
export const GET_ALL_SIZES_FAIL = 'GET_ALL_SIZES_FAIL';
export const GET_SLIDES_SUCCESS = 'GET_SLIDES_SUCCESS';
export const GET_SLIDES_FAIL = 'GET_SLIDES_FAIL';
export const UNSUBSCRIBE_SUCCESS = 'UNSUBSCRIBE_SUCCESS';
export const UNSUBSCRIBE_FAIL = 'UNSUBSCRIBE_FAIL';